import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          // primary: '#ff00ff',  // Override Vuetify's primary color
        },
      },
    },
  },
  components,
  directives,
})
