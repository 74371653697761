import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from '../components/HomeComponent.vue';
import FileUploadComponent from '../components/FileUploadComponent.vue';
import AuthCallback from '../components/AuthCallback.vue';
import { useAuth0 } from '@auth0/auth0-vue'; // Import Auth0
import { watchEffect } from 'vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeComponent
    },
    {
        path: '/callback',
        name: 'AuthCallback',
        component: AuthCallback,
    },
    {
        path: '/upload-file',
        name: 'Upload file',
        component: FileUploadComponent,
        meta: { requiresAuth: true }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Add a global beforeEach guard
router.beforeEach((to, from, next) => {
    const { isAuthenticated, isLoading } = useAuth0();
  
    // If the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Wait for Auth0 to finish loading
      watchEffect(() => {
        if (!isLoading.value) {
          if (isAuthenticated.value) {
            next(); // Allow access if the user is authenticated
          } else {
            next('/'); // Redirect to home or login page if not authenticated
          }
        }
      });
    } else {
      next(); // Always allow access if the route doesn't require authentication
    }
  });

export default router;