<template>
  <div id="app">
    <!-- Navigation Links -->
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/login">Login</router-link> |
      <router-link to="/protected">Protected</router-link>
    </nav> -->

    <!-- Router View: This is where the matched component will be displayed -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Add some basic styling */
nav {
  margin-bottom: 10px;
}

nav a {
  margin-right: 10px;
}
</style>
