<template>
  <div class="mainUploadfile">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center justify-center mb-4 mt-16 pt-16">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <label for="file-upload" class="custom-file-upload w-50 bg-red rounded text-capitalize">
              Upload file
            </label>
            <input id="file-upload" type="file" @change="handleFileChange" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center text-black mb-9">
        <p>{{ uploadStatus }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters class="h-100 d-flex align-center justify-center">
      <v-col cols="12" sm="10" md="8" lg="6" class="h-100">
        <v-card class="mx-auto h-100">
          <v-layout>
            <v-app-bar color="#cbd6e2" density="compact" class="px-5">
              <div class="d-flex align-center justify-center">
                <v-img src="../assets/cloudIcon.png" height="30px" width="30px" class="mx-4"></v-img>
                <p class="font-weight-bold text-h5">Supply Chain Solutions®</p>
              </div>
              <div class="d-flex align-center justify-center">
                <p class="font-weight-regular mx-5">Upload</p>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" v-bind="props">
                      <v-img src="../assets/menu.png" height="20px" width="20px" class="mx-4"></v-img>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title @click="handleLogout" class="cursor-pointer">Logout</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-app-bar>

            <v-main class="w-100 px-6 py-3">
              <h2 class="mb-5">Uploaded Files</h2>
              <v-row>
                <v-col cols="12" class="d-flex justify-center text-black mb-9">
                  <p>{{ fileListStatus }}</p>
                </v-col>
              </v-row>
              <!-- Uploaded Files List -->
              <div v-for="file in files" :key="file.name" class="mb-5">
                <v-card class="mx-auto mb-5">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex align-center">
                      <v-col cols="4" sm="4" md="4" lg="4">
                        <p>{{ file.name }}</p>
                      </v-col>
                      <v-col cols="5" sm="6" md="6" lg="6" class="d-flex justify-end">
                        <p>{{ file.lastModified }}</p>
                      </v-col>
                      <v-col cols="3" sm="2" md="2" lg="2" class="d-flex justify-end">
                        <v-btn variant="outlined" height="40" class="rounded text-capitalize"
                          @click="confirmDelete(file.name)">Delete</v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <v-btn @click="listFiles">Refresh File List</v-btn>
            </v-main>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this file?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="deleteFile">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { BlobServiceClient } from "@azure/storage-blob";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "FileUploadComponent",
  methods: {
    async handleLogout() {
      await this.$auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    }
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0()

    const selectedFile = ref(null);
    const uploadStatus = ref("");
    const fileListStatus = ref("");
    const files = ref([]);
    const accountName = process.env.VUE_APP_STORAGE_ACCOUNT_NAME;
    const dialog = ref(false);
    const fileToDelete = ref("");
    let sasToken = "";
    let userContainer = "";
    let authToken = "";

    const handleFileChange = (event) => {
      selectedFile.value = event.target.files[0];
      handleUpload();
    };

    const getSasToken = async () => {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_GENERATE_SAS_TOKEN}/api/GenerateSasToken`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Pass the token in the Authorization header
              'Content-Type': 'application/json'      // Optional: Ensure the content type is set
            }
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch SAS token");
        }
        //earlier we were getting single sas token, now we are genrated separate for upload and list
        const sasTokenResult = await response.json();
        sasToken = sasTokenResult.sasToken;
        userContainer = sasTokenResult.containerName;
      } catch (error) {
        throw new Error("Failed to fetch SCS token: " + error.message);
      }
    };

    const uploadFileToBlob = async (file) => {
      const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(userContainer);
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);

      try {
        await blockBlobClient.uploadData(file);
      } catch (error) {
        throw new Error("Failed to upload file: " + error.message);
      }
    };

    const handleUpload = async () => {
      if (selectedFile.value) {
        uploadStatus.value = "Uploading...";
        try {
          await uploadFileToBlob(selectedFile.value);
          uploadStatus.value = "File uploaded successfully!";
          await listFiles(); // Refresh the file list after upload
          // Clear the status message after 3 seconds
          setTimeout(() => {
            uploadStatus.value = "";
          }, 3000);

        } catch (error) {
          console.error("File upload error:", error);
          uploadStatus.value = "File upload failed. Please try again.";
        }
      } else {
        uploadStatus.value = "Please select a file first.";
      }
    };

    const listFiles = async () => {
      uploadStatus.value = "Loading...";
      try {
        authToken = await getAccessTokenSilently();
        await getSasToken();
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );
        const containerClient = blobServiceClient.getContainerClient(userContainer);

        const fileList = [];
        for await (const blob of containerClient.listBlobsFlat()) {
          fileList.push({
            name: blob.name,
            lastModified: new Date(blob.properties.lastModified).toLocaleString(),
          });
        }
        files.value = fileList;
        uploadStatus.value = "";
      } catch (error) {
        console.error("Failed to list files:", error);
      }
    };

    const confirmDelete = (fileName) => {
      fileToDelete.value = fileName;
      dialog.value = true; // Open the dialog
    };

    const deleteFile = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );
        const containerClient = blobServiceClient.getContainerClient(userContainer);
        const blobClient = containerClient.getBlobClient(fileToDelete.value);
        await blobClient.delete();
        dialog.value = false; // Close the dialog
        await listFiles(); // Refresh the file list after deletion
      } catch (error) {
        console.error("Failed to delete file:", error);
      }
    };

    listFiles();
    return {
      selectedFile,
      uploadStatus,
      fileListStatus,
      handleFileChange,
      handleUpload,
      listFiles,
      files,
      confirmDelete,
      deleteFile,
      dialog,
    };
  },
  mounted() {
    document.title = 'Manage files';
  },
};
</script>

<style lang="scss">
input[type="file"] {
  display: none;
}

.custom-file-upload {
  // display: inline-block;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
}

.mainUploadfile {
  background-color: #fff;
  min-height: 100vh;
  width: 100vw;
  padding: 0 15px;
  overflow: hidden;
}

.v-toolbar__content {
  height: 64px !important;
  justify-content: space-between;
}

.mt-7 {
  margin-top: 7rem;
}

@media (max-width: 600px) {
  .mt-7 {
    margin-top: 3rem;
    /* Smaller margin on mobile */
  }

  .v-btn {
    font-size: 14px;
    /* Smaller button text size on mobile */
  }

  h2 {
    font-size: 22px;
    /* Smaller heading on mobile */
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .mt-7 {
    margin-top: 5rem;
    /* Adjust for tablet screens */
  }

  .v-btn {
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
  }
}

@media (min-width: 960px) {
  .mt-7 {
    margin-top: 7rem;
    /* Full margin for larger screens */
  }

  .v-btn {
    font-size: 18px;
  }

  h2 {
    font-size: 28px;
  }
}
</style>
