<template>
  <div class="main d-flex align-center justify-center">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center justify-center">
        <v-col cols="11" sm="10" md="6" lg="4" class="bg-white d-flex align-center justify-center rounded py-5">
          <v-col cols="10">
            <v-img src="../assets/scs-logo_standard.svg" height="108px" class="mb-9 mt-8"></v-img>
            <p class="font-weight-light mb-12 text-center font-size-para">
              Welcome to <br> Supply Chain <br> Solutions®
            </p>
            <div v-if="isAuthenticated">
              <v-btn size="x-large" height="40" class="w-100 bg-red rounded mb-16" @click="logout">Logout</v-btn>
            </div>
            <div v-else>
              <v-btn size="x-large" height="40" class="w-100 bg-red rounded mb-16" @click="login">Login</v-btn>
            </div>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "HomeComponent",
  setup() {
    const { isAuthenticated } = useAuth0();
    return {
      isAuthenticated,
    };
  },
  mounted() {
    document.title = 'Login';
    this.redirectLoginUser();
  },
  methods: {
    async login() {
      await this.$auth0.loginWithRedirect();
    },
    async logout() {
      await this.$auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    },
    async redirectLoginUser() {
      const { isAuthenticated } = useAuth0();
      if (isAuthenticated.value) {
        this.$router.push({ path: '/upload-file' });
      }
    }
  }
};
</script>

<style lang="scss">
.main {
  background-color: #cbd6e2;
  min-height: 100vh;
  width: 100vw;
  padding: 0 15px;
  overflow: hidden;
}

.font-size-para {
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media (min-width: 960px) {
    font-size: 32px;
    line-height: 42px;
  }
}

.v-btn {
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: 18px;
  }

  @media (min-width: 960px) {
    font-size: 20px;
  }
}

.mt-8 {
  margin-top: 8px;
}

.mb-9 {
  margin-bottom: 9px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

body {
  overflow: hidden;
  /* Prevents the body from scrolling */
}
</style>
