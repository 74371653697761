<template>
  <div>
    <p>Processing authentication...</p>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { watchEffect } from 'vue';

export default {
  name: "AuthCallback",
  data() {
    return {
      redirectUrl: this.$route.query.redirect || "/upload-file",
    };
  },
  mounted() {
    this.handleCallback();
  },
  methods: {
    async handleCallback() {
      try {
        const { isAuthenticated, isLoading } = useAuth0();

        // Wait for Auth0 to finish loading
        watchEffect(() => {
          if (!isLoading.value) {
            if (isAuthenticated.value) {
              this.$router.push(this.redirectUrl);
            } else {
              this.$router.push('/');
            }
          }
        });
      } catch (error) {
        console.error("Error handling callback:", error);
      }
    },
  },
};
</script>