import { createApp } from "vue";
import App from "./App.vue";
import { createAuth0 } from "@auth0/auth0-vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./styles/style.scss"; // Import your custom CSS file

async function startApp() {
  try {
    createApp(App)
      .use(vuetify)
      .use(
        createAuth0({
          domain: process.env.VUE_APP_AUTH_DOMAIN,
          clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
          authorizationParams: {
            redirect_uri: process.env.VUE_APP_CALLBACK_URL,
            audience: process.env.VUE_APP_AUTH_AUDIENCE
          },
          cacheLocation: "localstorage", // Persist authentication across reloads
          useRefreshTokens: true, // Optional: Enables silent authentication with refresh tokens
        })
      )
      .use(router)
      .mount("#app");
  } catch (error) {
    console.error("App initialization failed", error);
  }
}

startApp(); // Call the function to start the app
